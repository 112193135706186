import { lazy, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import Index from "./components/onBoarding";
import Refresh from "./pages/Refresh";
import RemoteCart from "./pages/RemoteCart";
import SelectStore from "./pages/SelectStore";
import EmailVerification from "./pages/auth/EmailVerification";
import IosEmailVerify from "./pages/auth/IosEmailVerify";
import IsoLogin from "./pages/auth/IsoLogin";
import IosSignUp from "./pages/auth/IsoSignUp";
import IsoSuccess from "./pages/auth/IsoSuccess";
import StorageServices from "./services/StorageServices";
import { ToastContainer } from "./utils/toast";

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/auth/Login"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const ForgetPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const AcceptInvite = lazy(() => import("./pages/auth/AcceptInvite"));

const App = () => {
  const activeStore = StorageServices.get("SB-Active-Store")
    ? JSON.parse(StorageServices.get("SB-Active-Store"))
    : "";
  //Notify serviceworker that user is offline
  useEffect(() => {
    const mode = localStorage.getItem("SB-Mode");
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage({ mode });
      });
    }
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined") {
      // require("bootstrap/dist/js/bootstrap.bundle.min.js");
      //const script = document.createElement("script");
      //script.id = "sb-chat-jssdk";
      //script.src = "https://business.storebounty.ng/messaging/webchat/sdk.js?opn=false&_id=5564&_name=Store+Bounty+Inc.&channelId=0&bgColor=black+%21important&textColor=%23ffffff+%21important";
      //const firstScriptTag = document.getElementsByTagName("script")[0];
      //firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    }
  }, []);

  return (
    <div className="scroll-behavior: smooth">
      <Helmet>
        <title>
          {activeStore.name ? activeStore.name + `${" |"}` : ""} Store Bounty
        </title>
      </Helmet>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/refresh" component={Refresh} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/login/ios" component={IsoLogin} exact />
          <Route path="/ios/success" component={IsoSuccess} exact />
          <Route path="/signup" component={SignUp} exact />
          <Route path="/signup/ios" component={IosSignUp} exact />
          <Route path="/remotecarts" component={RemoteCart} exact />
          <Route path="/verify" component={EmailVerification} exact />
          <Route path="/verify/ios" component={IosEmailVerify} exact />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/select-store" component={SelectStore} />
          <Route path="/onboarding" component={Index} />
          <Route path="/accept-invite" component={AcceptInvite} />
          <PrivateRoute>
            <Route path="/" component={Layout} />
          </PrivateRoute>{" "}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
