import React, { createContext, useState } from "react";

const HelpsAndGuidesContext = createContext();

export function HelpsAndGuidesProvider({ children }) {
  const [activeContent, setActiveContent] = useState("");
  const [showHelps, setShowHelps] = useState("");

  const getHelps = (id) => {
    const res = helps?.find((item) => item.id === id);
    setActiveContent(res);
    setShowHelps(true);
  };

  const closeDrawer = () => {
    setShowHelps(false);
    setActiveContent("");
  };

  return (
    <HelpsAndGuidesContext.Provider
      value={{ showHelps, setShowHelps, closeDrawer, activeContent, getHelps }}
    >
      {children}
    </HelpsAndGuidesContext.Provider>
  );
}

export default HelpsAndGuidesContext;

const helps = [
  {
    id: 1,
    title: "Default Web Store",
    content: `
        <p>
        Expand Your Business Online - Sell on Our Established Platform, for Free!
        </p
        <p>
        You already have a ready-made subdomain that offers you the opportunity to sell 
        your products online using our free. Our user-friendly platform makes it easy to 
        set up an online store and start selling to customers from all over the world. Plus, 
        you'll benefit from our established website traffic and SEO, helping to increase your 
        online visibility and drive more sales to your business.
        </p>
        
        <p>
        Our platform is low-cost and low-risk, making it the perfect solution for businesses of 
        all sizes. With our help, you can expand your reach, increase your sales, and take your 
        business to the next level - all for free.
        </p>
        `,
  },
  {
    id: 2,
    title: "Point of Sale",
    content: `
        <p>
        A Point of Sale (POS) system offers several advantages for businesses. 
        Firstly, it streamlines the sales process, making it easier and quicker to process transactions. 
        Secondly, a POS system helps with inventory management by tracking inventory levels in real-time, 
        allowing businesses to manage their stock more efficiently and avoid stockouts. Additionally, 
        a POS system improves the customer experience by reducing errors, speeding up transactions, 
        and enabling easy refunds and returns. Moreover, a POS system collects and stores data on sales, 
        inventory, and customer behavior, providing valuable insights for decision-making and strategy. 
        The system can also help manage employee schedules, track sales performance, and provide training and support to staff. 
        Finally, a POS system can be integrated with other business systems, such as accounting and marketing software, 
        to streamline operations and improve efficiency. Overall, a POS system is an essential tool for businesses of all sizes, 
        helping to improve efficiency, accuracy, and profitability.
        </p>
        `,
  },
  {
    id: 3,
    title: "Integrating Mobile App",
    content: `
        <p>
        Yes, integrating a mobile app can be a great way to offer your customers a more convenient shopping experience. 
        By providing them with an app that they can download and install on their Android or iOS device, 
        you can make it easier for them to browse your products, make purchases, and engage with your brand.
    
        </p
        <p>
        There are a number of benefits to integrating a mobile app with your store. First and foremost, 
        it can help you reach more customers by making your store more accessible on mobile devices.
        </p>
        `,
  },
  {
    id: 4,
    title: "Business to Business (B2B)",
    content: `
        <p>
        B2B (Business-to-Business) refers to the sales and transactions that occur 
        between two or more businesses, rather than between a business and individual consumers. 
        In a B2B model, one business sells products or services to another business.
        </p
        <p>
        B2B sales typically involve higher transaction volumes and 
        larger order values than B2C (Business-to-Consumer) sales, as businesses 
        tend to buy in bulk and make repeat purchases over time. B2B sales also often involve 
        negotiations and contracts, as businesse
        </p>  
        `,
  },
  {
    id: 5,
    title: "Messaging",
    content: `
        <p>
            Messaging is the process of sending messages between businesses.
            It is a way of communicating between businesses and consumers.
        </p>
        <p>
        A messaging system can be used to communicate with both
        businesses and consumers.
        </p>  
        `,
  },
];
