import React, { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import Storebounty_logo from "../../assets/img/Storebounty_icon.png";

export default function IsoSuccess() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Set a timeout for 30 seconds to handle fallback in case of delay
    const timeout = setTimeout(() => {
      setError(true);  // Show error or fallback UI after 20 seconds
      setLoading(false);  // Stop loading animation
    }, 20000);

    // Cleanup timeout if component unmounts or if OTP validation completes
    return () => clearTimeout(timeout);
  }, []);

  const handleReload = () => {
    setLoading(true);
    setError(false);
    window.location.reload();
  };

  return (
    <div className="min-h-screen overflow-auto bg-green-50 py-20 px-3">
      <div className="container mx-auto">
        <div className="max-w-lg mx-auto">
          <div className="w-full">
            <div className="bg-white py-3 rounded shadow-lg text-center text-sm">
              <img
                src={Storebounty_logo}
                alt="Storebounty Logo"
                className="w-40 h-auto mx-auto"
              />

              <div className="h-48 flex flex-col justify-center items-center">
                {loading && !error ? (
                  <FadeLoader color="#0230B1" />
                ) : error ? (
                  <div className="text-red-600 text-center">
                    <p>Something went wrong. Please try again later.</p>
                    <button
                      onClick={handleReload}
                      className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      Reload
                    </button>
                  </div>
                ) : (
                  <div className="text-green-600">Processing successful!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
