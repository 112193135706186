import { useEffect } from "react";
import StorageServices from "../services/StorageServices";

export default function Refresh() {
  useEffect(() => {
    StorageServices.clearAll();
    if (window.location.pathname.includes("ios")) {
      window.location = "/login/ios";
    } else {
      window.location = "/login";
    }
  }, []);

  return null;
}
