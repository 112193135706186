import { Switch } from "@headlessui/react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  Select,
} from "@windmill/react-ui";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useContext, useMemo, useState, useEffect } from "react";
import { FiX } from "react-icons/fi";
import Image2 from "../../assets/illustrations/online-shopping-store.png";
import Image1 from "../../assets/illustrations/online-shopping.png";
import Storebounty_logo from "../../assets/img/Storebounty_icon.png";
import { modulesImages } from "../../constants";
import { SidebarContext } from "../../context/SidebarContext";
import useStoreSubmit from "../../hooks/formSubmits/useStoreSubmit";
import useAsync from "../../hooks/useAsync";
import ModuleServices from "../../services/ModuleServices";
import SelectProductType from "../dropdown/SelectProductType";
import Error from "../form/Error";
import InputArea from "../form/InputArea";
import ModuleCard from "../modules/ModuleCard";
import BackButton from "../onBoarding/BackButton";
import Loading from "../preloader/Loading";
import Hints from "../question/Question";
import webCurrencyCodes from "../../utils/webCurrencyCodes";
import useIdentity from "../../hooks/useIdentity";
import getCurrencyByCountryCode from "../../utils/getCurrencyByCountryCode";

// TODO Sync with onboarding
const activeModules = {
  sellOnline: ["module_online", "module_marketing", "module_messaging"],
  sellInPerson: ["module_pos", "module_expenses"],
  sellInBoth: ["module_online", "module_marketing"],
};

export default function CreateStore({ data }) {
  //   const { data, loading } = useAsync(ProductServices.getProduct_0);
  const { data: module, loading } = useAsync(ModuleServices.getModules);

  const { gettingIdentity, countryCode } = useIdentity();

  const defaultCurrency = getCurrencyByCountryCode(countryCode);

  const moduleState = module?.data;
  const { create, setCreate } = useContext(SidebarContext);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setCreate(false);
    setStep(1);
  };
  const {
    register,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    watchProductType,
    productType,
    setProductType,
    web_support,
    pos_support,
    currency_code,
    sub_domain,
    store_module,
    mode,
    Controller,
    control,
    watch,
    setValue,
    moduleObj,
  } = useStoreSubmit({ handleClose });

  useEffect(() => {
    if (!gettingIdentity) {
      setSelectedCurrency(defaultCurrency);
    }
  }, [gettingIdentity, defaultCurrency]);

  const moduleSelectedForYou = useMemo(() => {
    let selectedModules = [];
    if (pos_support) {
      selectedModules = activeModules.sellInPerson;
    }
    if (web_support) {
      selectedModules = activeModules.sellOnline;
    }
    if (pos_support && store_module === "restaurant") {
      selectedModules.push("module_food_menu");
    }
    selectedModules.forEach((item) => {
      setValue(item, "active");
    });

    return selectedModules;
  }, [pos_support, web_support, store_module]);

  return (
    <>
      <Modal
        isOpen={create}
        onClose={handleClose}
        style={{
          padding: 0,
          width: "100vw",
          minWidth: "100vw",
          height: "100vh",
          overflow: "scroll",
          // backgroundColor: "white",
        }}
      >
        <button
          onClick={handleClose}
          type="button"
          className="absolute focus:outline-none z-50 flex lg:mr-16 mr-4 mt-8 lg:text-base text-sm font-semibold items-center gap-1 hover:text-gray-700  text-red-500 hover:bg-red-100  transition-colors duration-150   right-0 left-auto  rounded px-4  text-center"
        >
          <FiX className="mx-auto" /> close
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          {step === 1 && (
            <ModalBody className=" max-w-3xl mx-auto flex flex-col justify-center h-full">
              <div className="mx-12 pt-6 pb-4 border-b dark:border-gray-800  dark:text-gray-300 ">
                <p className="font-medium  text-lg text-center mt-10">
                  Create A New Store
                </p>
                <p className="text-sm text-center mt-2">
                  Please fill in the details below to create a new store
                </p>
              </div>
              {isLoading && (
                <div className="absolute inset-x-0 top-1/2 mt-64 z-50">
                  <Loading loading={isLoading} />
                </div>
              )}

              <div className="px-12 pt-4 mb-8 flex-grow">
                <div className="flex flex-col gap-1 mb-5">
                  <div>
                    <Label className="font-medium">Business Name</Label>
                    <Hints
                      hint={
                        "Input name you want your store to be identified with."
                      }
                    />
                  </div>

                  <div className="">
                    <InputArea
                      register={register}
                      label="Business Name name"
                      name="name"
                      type="text"
                      placeholder="Enter a business name"
                      required={true}
                    />
                    <Error errorName={errors.name} />
                  </div>
                </div>

                <div className="flex flex-col gap-1 mb-5">
                  <div>
                    <Label className="font-medium">
                      Select store default currency
                    </Label>
                    <Hints
                      hint={"Select default currency. This cannot be changed."}
                    />
                  </div>
                  <div className="">
                    <Select
                      className="h-10"
                      {...register("currency_code")}
                      value={selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                      <option value="" className="hidden">
                        Select a currency
                      </option>
                      {Object.keys(webCurrencyCodes).map((currency, i) => (
                        <option value={webCurrencyCodes[currency]} key={i}>
                          {`${
                            webCurrencyCodes[currency]
                          } (${getSymbolFromCurrency(
                            webCurrencyCodes[currency]
                          )})`}
                        </option>
                      ))}
                    </Select>
                    <Error errorName={errors.currency_code} />
                  </div>
                </div>
                <div className="flex flex-col gap-1 mb-5">
                  <div>
                    <Label className="font-medium">Product Type</Label>
                    <Hints
                      hint={
                        "Select the type of products to be sold in your store."
                      }
                    />
                  </div>

                  <div className="">
                    {data?.data && (
                      <SelectProductType
                        register={register}
                        data={data?.data?.product_types}
                        watchProductType={watchProductType}
                        productType={productType}
                        setProductType={setProductType}
                      />
                    )}
                    <Error errorName={errors.product_type_id} />
                  </div>
                </div>
                <div className="flex flex-col gap-1 mb-5">
                  <Label className="font-medium">Your free storefront</Label>

                  <div className="flex items-center">
                    <InputArea
                      register={register}
                      label="sub_domain"
                      name="sub_domain"
                      type="text"
                      placeholder=""
                      required={true}
                    />
                    <p className="text-green-500 dark:text-white font-medium">
                      <span>.storebounty.ng</span>
                    </p>
                    <Error errorName={errors.sub_domain} />
                  </div>
                </div>
                {/* <div className="flex flex-col gap-1 mb-5">
                <LabelArea label="Description" />
                <div className="">
                
                  <Textarea {...register("description")}></Textarea>
                </div>
              </div> */}
                <div className="mt-8">
                  <Button
                    className="w-full"
                    type="button"
                    onClick={() => setStep(2)}
                    disabled={!currency_code || !sub_domain}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </ModalBody>
          )}
          {step === 2 && (
            <div className="min-h-screen bg-green-50 flex justify-center items-center px-3">
              <div className="container mx-auto">
                <div className="max-w-xl mx-auto w-full lg:px-8 ">
                  <div className="w-full max-w-lg mx-auto">
                    <div className="bg-white  lg:px-8 rounded-md shadow-lg text-center text-sm">
                      <img
                        src={Storebounty_logo}
                        alt=""
                        className="w-40 h-auto mx-auto"
                      />
                      <h1 className="font-medium text-lg mt-2">
                        Where would you like to sell?
                      </h1>
                      <p className="text-xs mt-2 text-gray-500  w-8/12 mx-auto">
                        Leave the box checked to allow you sell via multiple
                        sales channel.
                      </p>
                      <fieldset>
                        <label
                          htmlFor="online_store"
                          className="flex items-center gap-2 text-left px-4 mt-4"
                        >
                          <Input
                            type="checkbox"
                            className="w-5 h-5"
                            id="online_store"
                            {...register("web_support")}
                            value={web_support}
                            checked={web_support}
                          />
                          <div className="flex gap-2 border p-2 rounded">
                            <img
                              src={Image2}
                              alt=""
                              className="w-20 h-auto object-contain"
                            />
                            <div className="">
                              <p className="text-sm">Online Store</p>
                              <span className="text-xs text-gray-600">
                                Your online store allows you sell your products
                                via web sales channel(s).
                              </span>
                            </div>
                          </div>
                        </label>
                        <label
                          htmlFor="pos_support"
                          className="flex items-center gap-2 text-left px-4 mt-4"
                        >
                          <Input
                            type="checkbox"
                            className="w-5 h-5"
                            id="pos_support"
                            {...register("pos_support")}
                            checked={pos_support}
                          />
                          <div className="border p-2 rounded">
                            <div className="flex gap-2 ">
                              <img
                                src={Image1}
                                alt=""
                                className="w-20 h-auto object-contain"
                              />
                              <div className="">
                                <p className="text-sm">In Person</p>
                                <span className="text-xs text-gray-600">
                                  Point of sale allows you record and make sales
                                  manually and in person.
                                </span>
                              </div>
                            </div>
                            {pos_support && (
                              <div className="ml-8 mt-4  divide-y divide-gray-100">
                                <label
                                  htmlFor="general_store"
                                  className="flex gap-2 py-3 cursor-pointer"
                                >
                                  <Input
                                    type="radio"
                                    id="general_store"
                                    value={"general_store"}
                                    {...register("store_module")}
                                    checked={
                                      watch("store_module") === "general_store"
                                    }
                                  />
                                  <div>
                                    <Label>Retail Point of Sale</Label>
                                    <Hints hint={"For general stores"} />
                                  </div>
                                </label>
                                <label
                                  htmlFor="restaurant"
                                  className="flex gap-2 py-3 cursor-pointer"
                                >
                                  <Input
                                    type="radio"
                                    name="store_module"
                                    id="restaurant"
                                    value={"restaurant"}
                                    {...register("store_module")}
                                    checked={
                                      watch("store_module") === "restaurant"
                                    }
                                  />
                                  <div>
                                    <Label>Restaurant Point of Sale</Label>
                                    <Hints hint={"For restaurants and bars"} />
                                  </div>
                                </label>
                              </div>
                            )}
                          </div>
                        </label>
                        <div className="mt-5 px-4 w-full mx-auto flex justify-between">
                          <BackButton setStep={() => setStep(1)} />
                          <Button
                            disabled={!pos_support && !web_support}
                            onClick={() => setStep((prev) => prev + 1)}
                            className="px-24 my-6"
                            style={{ borderRadius: "4px" }}
                          >
                            Continue
                          </Button>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="container mx-auto">
              <div className=" p-6 lg:p-8 lg:px-10  ">
                <img
                  src={Storebounty_logo}
                  alt=""
                  className="w-40 h-auto mb-6"
                />

                <h1 className="font-medium text-lg  mt-6">
                  Suitable modules for your account type
                </h1>
                <p className="text-xs  mt-1 text-gray-500  w-8/12">
                  The modules are selected based on your account type, you can
                  add or remove from these modules
                </p>

                <div className="mt-8">
                  <h2 className="py-4 text-sm font-medium">
                    Available modules
                  </h2>
                  <form className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {moduleState &&
                      moduleSelectedForYou?.map((item) => {
                        const moduleInfo = moduleState[item];
                        const isActive = watch(item);

                        return (
                          <ModuleCard
                            key={item}
                            saving={false}
                            title={moduleInfo?.title}
                            value={isActive}
                            handleChange={(name, value) => {
                              setValue(name, value ? "active" : "disabled");
                            }}
                            name={item}
                            image={modulesImages[item]}
                            description={moduleInfo?.description}
                          />
                        );
                      })}
                  </form>

                  <Loading loading={loading} />

                  <h2 className="py-4 text-sm font-medium">
                    Available modules
                  </h2>
                  <form className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {moduleState &&
                      Object.entries(moduleState)
                        ?.filter(([key]) => !moduleSelectedForYou?.includes(key))
                        ?.map(([key, values]) => (
                          <ModuleCard
                            key={key}
                            saving={false}
                            title={values.title}
                            value={watch(key)}
                            handleChange={(name, value) => {
                              setValue(name, value ? "active" : "disabled");
                            }}
                            name={key}
                            image={values.image}
                            description={moduleState[key]?.description}
                          />
                        ))}
                  </form>
                </div>
                <div className="lg:col-span-2 mt-8 px-4 w-full mx-auto flex justify-between">
                  <BackButton setStep={setStep} />
                  <Button
                    disabled={!pos_support && !web_support}
                    onClick={() => setStep((prev) => prev + 1)}
                    type="button"
                    className="lg:px-12 px-6 my-6"
                    style={{ borderRadius: "4px" }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="min-h-screen bg-green-50 flex justify-center items-center px-3">
              <div className="container mx-auto">
                <div className="max-w-xl mx-auto w-full lg:px-8">
                  <div className="w-full max-w-lg mx-auto">
                    <div className="bg-white  lg:px-8 rounded-md shadow-lg text-center text-sm">
                      <img
                        src={Storebounty_logo}
                        alt=""
                        className="w-40 h-auto mx-auto"
                      />
                      <h1 className="font-medium text-lg mt-2">
                        Select Your Store Mode
                      </h1>
                      <p className="text-xs mt-2 text-gray-500  w-8/12 mx-auto">
                        Test Mode comes with experimental products, Select Live
                        Mode if you want to start selling immediately.
                      </p>
                      <Loading loading={isLoading} />
                      <fieldset>
                        <label htmlFor="test">
                          <div className="flex items-center gap-2 text-left px-4 mt-4">
                            <Input
                              type="radio"
                              className="w-5 h-5"
                              id="test"
                              {...register("mode")}
                              value="test"
                              checked={mode === "test"}
                            />
                            <div className=" p-2  border">
                              <div className="px-1">
                                <p className="text-sm">Test Mode</p>
                                <span className="text-xs text-gray-600">
                                  Test payment methods, and evaluate the
                                  performance of the store without affecting
                                  actual sales.
                                </span>
                              </div>
                              {mode === "test" && (
                                <div className="flex justify-between items-center mt-2">
                                  <span className="text-xs font-medium  text-gray-600">
                                    Add experimental products for testing
                                  </span>
                                  <Controller
                                    control={control}
                                    name="test_product"
                                    render={({
                                      field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                      },
                                    }) => (
                                      <Switch
                                        checked={value}
                                        onChange={onChange}
                                        className={`${
                                          value ? "bg-green-600" : "bg-gray-200"
                                        } relative inline-flex h-6 w-12 items-center rounded-full focus:outline-none`}
                                      >
                                        <span className="sr-only">
                                          Enable notifications
                                        </span>
                                        <span
                                          className={`${
                                            value
                                              ? "translate-x-6"
                                              : "translate-x-1"
                                          } inline-block h-4 w-5 transform rounded-full bg-white transition`}
                                        />
                                      </Switch>
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </label>

                        <label
                          htmlFor="live"
                          className="flex items-center gap-2 text-left px-4 mt-4"
                        >
                          <Input
                            type="radio"
                            className="w-5 h-5"
                            id="live"
                            value="live"
                            {...register("mode")}
                            checked={mode === "live"}
                          />
                          <div className="flex gap-2 border p-2 ">
                            <div className="px-1">
                              <p className="text-sm">Live Mode</p>
                              <span className="text-xs text-gray-600">
                                Your store is open for business and customers
                                can make real purchases
                              </span>
                            </div>
                          </div>
                        </label>
                        <div className="mt-5 px-4 w-full mx-auto flex justify-between">
                          <BackButton setStep={setStep} />
                          <Button
                            disabled={!mode || isLoading}
                            type="submit"
                            className="px-24 my-6"
                            style={{ borderRadius: "4px" }}
                          >
                            Create Store
                          </Button>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </Modal>
    </>
  );
}
