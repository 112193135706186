import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import AdminServices from "../../services/AdminServices";
import StorageServices from "../../services/StorageServices";
import { getIP } from "../../utils/getIP";
import { notifyError, notifySuccess } from "../../utils/toast";

const useIosAuth = () => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AdminContext);
  const history = useHistory();
  //const { gettingIdentity, countryCode, ipAddress } = useIdentity();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const { password, email } = getValues();
  const remember_me = StorageServices.get("SB-remember-me");
  const activeEmail = StorageServices.get("logged-in-email");

  useEffect(() => {
    if (remember_me && remember_me !== "null") {
      activeEmail && setValue("email", activeEmail);
      remember_me && setValue("remember_me", remember_me);
    }
    //eslint-disable-next-line
  }, []);

  const onLoginSubmit = async (data, e) => {
    const { email, password } = data;

    if (!email || !password) {
      notifyError("Please fill all fields");
      return;
    }

    setLoading(true);
    try {
      const IP = await getIP();
      const res = await AdminServices.loginIos({ email, password }, IP);
      if (res?.data?.otp) {
        history.push(`/ios/success?otp=${res?.data?.otp}`);
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
    } finally {
      setLoading(false);
    }
  };

  const onSignUp = async (data, e) => {
    // e.preventDefault();
    const { email, password, first_name, last_name, phone, referral_code } =
      data;

    setLoading(true);
    try {
      const IP = await getIP();
      const res = await AdminServices.registerIos(
        {
          email,
          password,
          first_name,
          last_name,
          phone,
          referral_code,
        },
        IP
      );
      const userToken = res?.token;
      Cookies.set("SB-user-token", "Bearer " + userToken);
      history.push("/verify/ios", {
        data: res.data,
        email,
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setLoading(false);
    }
  };

  const onVerifyEmail = async (data, e) => {
    e.preventDefault();

    const { otp } = data;

    if (!otp || otp.length < 4) {
      notifyError("Please enter a valid token");
      return;
    }
    setLoading(true);

    try {
      const res = await AdminServices.verifyEmail({
        otp,
        source: "ios",
        include_otp: true,
      });

      if (res?.data?.token) {
        history.push(`/ios/success?otp=${res?.data?.otp}`);
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
      setLoading(false);
    }
  };

  const onsendEmailOtp = async (data, e) => {
    if (!data.email) {
      return;
    }
    const payload = {
      app_id: "storebounty",
      email: data.email,
    };
    setLoading(true);
    try {
      const otp = await AdminServices.sendEmailOTP(payload);
      StorageServices.set("email", data.email);
      notifySuccess(otp.message);
      // console.log(res);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.data?.message ||
        error.message ||
        error.toString();
      notifyError(message);
    } finally {
      setLoading(false);
    }
  };

  return {
    onLoginSubmit,
    onSignUp,
    onVerifyEmail,
    register,
    handleSubmit,
    errors,
    loading,
    password,
    Controller,
    control,
    reset,
    email,
    onsendEmailOtp,
  };
};

export default useIosAuth;
